import * as Sentry from '@sentry/browser';

Sentry.init({
  enabled: import.meta.env.PROD,
  // release: takes from window.SENTRY_RELEASE.id
  dsn: 'https://73d23c8a934b4230874f942a79021e49@sentry.avvoka.com/2',
  integrations: [
    Sentry.browserTracingIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
